<template>
  <div class="index">
    <div class="resources-content">
      <el-breadcrumb
        separator=">"
        class="genera-breadcrumb"
        style="margin-left: 20px"
      >
        <el-breadcrumb-item>实训中心</el-breadcrumb-item>
        <el-breadcrumb-item>查看直播</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="scro">
        <el-scrollbar>
          <el-table
            :data="liveTable"
            style="width: 100%; flex: 1"
            class="customTable"
            @sort-change="sort"
          >
            <el-table-column label="排名" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.step == 1"
                  ><img
                    style="width: 20px"
                    :src="require('../../../assets/image/one.png')"
                    alt=""
                /></span>
                <span v-else-if="scope.row.step == 2"
                  ><img
                    style="width: 20px"
                    :src="require('../../../assets/image/two.png')"
                    alt=""
                /></span>
                <span v-else-if="scope.row.step == 3"
                  ><img
                    style="width: 20px"
                    :src="require('../../../assets/image/theer.png')"
                    alt=""
                /></span>
                <span v-else>{{ scope.row.step }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="class_num" label="直播" align="center">
              <template slot-scope="scope">
                <div class="goods-wrapper" @click="seeLive(scope.row)">
                  <div class="goods-cover">
                    <img
                      style="border-radius: 8px"
                      :src="scope.row.live_cover"
                      alt=""
                    />
                  </div>
                  <div
                    class="text-overflow-2 goods-name"
                    :title="scope.row.live_title"
                  >
                    <span>{{ scope.row.live_title }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="学生名称" align="center">
              <template slot-scope="scope">
                <div class="goods-wrapper" @click="dataBoard(scope.row)">
                  <div class="goods-cover">
                    <img
                      style="border-radius: 25px"
                      :src="scope.row.live_stu_avatar"
                      alt=""
                    />
                  </div>
                  <div
                    class="text-overflow-2 goods-name"
                    :title="scope.row.nickname"
                  >
                    <span>{{ scope.row.nickname }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="begin_time"
              label="开播时间"
              align="center"
             
              sortable
            ></el-table-column>
            <el-table-column
              prop="duration_time"
              label="直播时长"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="max_online_num"
              label="人气峰值"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="watch_num"
              label="观看人次"
              align="center"
              sortable
            ></el-table-column>
            <el-table-column
              prop="goods_num"
              label="商品数"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sales"
              label="销售额"
              align="center"
              sortable
            ></el-table-column>
            <el-table-column
              prop="order_num"
              label="销量"
              align="center"
              sortable
            ></el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <el-pagination
        class="pages-center"
        :current-page="coursePages.currentPageNum"
        :page-size="coursePages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="coursePages.total"
        @current-change="courseCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getOtherLive } from "@/utils/apis";
export default {
  data() {
    return {
      liveTable: [
        
      ],
      form: {
        sort_type: "",
        sort: "",
      },
      //分页
      coursePages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      let params = {
        train_id: this.$route.query.id,
        paging: 1,
        page: this.coursePages.currentPageNum,
        pageSize: this.coursePages.eachPageNum,
        sort: this.form.sort,
        sort_type: this.form.sort_type,
      };
      getOtherLive(params)
        .then((res) => {
          if (res.code == 200) {
        
            this.liveTable = res.data.list;
              
            // for(let i=0;i<this.liveTable.length;i++){
            //    if(this.liveTable[i].live_title==""){
            //     this.liveTable[i].live_title ='暂无数据'
            //   }
            //   if(this.liveTable[i].live_stu_avatar==""){
            //     this.liveTable[i].live_stu_avatar ='暂无数据'
            //   }

            //   if(this.liveTable[i].duration_time==""){
            //     this.liveTable[i].duration_time ='暂无数据'
            //   }
            //   if(this.liveTable[i].max_online_num==""){
            //     this.liveTable[i].max_online_num ='暂无数据'
            //   }
            //   if(this.liveTable[i].watch_num==""){
            //     this.liveTable[i].watch_num ='暂无数据'
            //   }
            //    if(this.liveTable[i].goods_num==""){
            //     this.liveTable[i].goods_num ='暂无数据'
            //   }
            //   if(this.liveTable[i].sales==""){
            //     this.liveTable[i].sales ='暂无数据'
            //   }
            //    if(this.liveTable[i].order_num==""){
            //     this.liveTable[i].order_num ='暂无数据'
            //   }
            // }
              // console.log( this.liveTable,'888')
            this.coursePages.total = res.data.total;
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    dataBoard(row) {
      if(row.status ==1||row.status ==3){
            let routeUrl = this.$router.resolve({
            name: "trainLiveDatas",
            path: "/livedata/trainLivedatas",
            query: {
              tid: row.user_id,
              bind_id: Number(localStorage.getItem('teacherId')),
              t_Train_id: row.train_id,
              t_Room_id: row.room_id,
            },
          });
          window.open(routeUrl.href, "_blank");
      }else if(row.status ==2){
            this.$message.warning("该学生直播已结束");
      }
 
    },
    // timestampToTime(row, column) {
    //   if(row.begin_timestamp !=''){
    //      var date = new Date(row.begin_timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    //       var Y = date.getFullYear() + "-";
    //       var M =
    //         (date.getMonth() + 1 < 10
    //           ? "0" + (date.getMonth() + 1)
    //           : date.getMonth() + 1) + "-";
    //       var D = date.getDate() + " ";
    //       var h = date.getHours() + ":";
    //       var m = date.getMinutes() + ":";
    //       var s = date.getSeconds();
    //       return Y + M + D + h + m + s;
    //   }else{
    //      return "暂未开播"
    //   }
     
    // },

    sort(column) {
      if (column.order == "ascending") {
        this.form.sort_type = "asc";
        this.form.sort = column.prop;
        this.getDate();
      } else if (column.order == "descending") {
        this.form.sort_type = "desc";
        this.form.sort = column.prop;
        this.getDate();
      }
    },
    // 切换分页
    courseCurrentChange(val) {
      this.coursePages.currentPageNum = val;
      // this.getCommodityList()
    },

    seeLive(row) {
      let LiveUrlLast = row.play_url.trim();
      let routeUrl = this.$router.resolve({
        name: "seeStudentLive",
        path: "/toSeeLive/StudentLive",
        query: {
          tid: row.user_id,
          bind_id: Number(localStorage.getItem('teacherId')),
          t_Train_id: row.train_id,
          t_Room_id: row.room_id,
          play_url: LiveUrlLast,
          script_id: row.train_info.script_id,
          advert_id: row.train_info.advert_id,
        },
      });
      window.open(routeUrl.href, "_blank");
    }
  },
};
</script>


<style lang='scss' scoped>
.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.table {
  margin-top: 30px;
}
.goods-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .goods-cover {
    width: 50px;
    height: 50px;
    display: flex;
    background: #f6f6f6;
    border-radius: 8px;
    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
  .goods-name {
    //   width: 1%;
    //   flex: 1;
    margin-left: 10px;
    font-size: 14px;
  }
}
.scro {
  height: 100%;
  padding-top: 30px;
  .el-scrollbar {
    height: 100%;
  }
}
.pages-center {
  margin: 20px 0 20px;
  text-align: center;
}
::v-deep .customTable th:first-child > .cell {
  padding-left: 30px;
}
::v-deep .customTable td:first-child > .cell {
  padding-left: 30px;
}
</style>